.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.dialog .toast-mask {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  width: 100%;
  height: 100%;
}
.dialog .toast {
  width: 4.68rem;
  height: 3.08rem;
  background: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 100;
  transform: translate(-50%, -50%);
  border-radius: 0.2rem;
}
.dialog .toast .toast-container {
  width: 4.68rem;
  height: 2.04rem;
}
.dialog .toast .toast-container .dialog-top {
  width: 4.68rem;
  height: 2.04rem;
  border-bottom: 0.02rem solid #F5F5F5;
  letter-spacing: 0.02rem;
}
.dialog .toast .toast-container .dialog-top .success {
  width: 100%;
  height: 0.4rem;
  font-size: 0.28rem;
  font-weight: 500;
  color: #333333;
  line-height: 0.4rem;
  margin: 0.34rem 0 0 0.4rem;
}
.dialog .toast .toast-container .dialog-top .myPage {
  text-align: center;
  color: #333333;
  font-size: 0.28rem;
  font-weight: 500;
  padding: 0.2rem 0 0.7rem 0;
}
.dialog .toast .toast-container .dialog-top .success-point {
  color: #005B8A;
  font-size: 0.4rem;
  text-align: center;
  padding: 0.5rem 0 0.3rem 0;
}
.dialog .toast .toast-container .dialog-btn {
  height: 1.04rem;
  position: relative;
  letter-spacing: 0.02rem;
}
.dialog .toast .toast-container .dialog-btn .btn {
  width: 2.335rem;
  height: 1.04rem;
  float: left;
  text-align: center;
  line-height: 1.04rem;
}
.dialog .toast .toast-container .dialog-btn .close-btn {
  font-size: 0.24rem;
  color: #acacac;
}
.dialog .toast .toast-container .dialog-btn .confirm-btn {
  color: #005B8A;
  font-size: 0.28rem;
}
.dialog .toast .toast-container .dialog-btn .road {
  float: left;
  width: 0.01rem;
  height: 0.42rem;
  border: 0.01rem solid #F5F5F5;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -0.005rem;
  margin-top: -0.21rem;
}
