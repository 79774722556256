.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.p_nickname {
  width: 100%;
  font-size: 0.26rem;
  padding: 0.1rem 0.1rem 0.2rem 0.4rem;
  background: #fff;
}
.my-container {
  /*  订单*/
}
.my-container .sign-up {
  width: 1rem;
  height: 0.48rem;
  font-size: 0.24rem;
  color: #FFFFFF;
  background: var(--main-color);
  position: absolute;
  top: 0.25rem;
  right: 0;
  border-radius: 0.28rem 0px 0px 0.28rem;
  text-align: center;
  line-height: 0.48rem;
  font-weight: lighter;
  z-index: 99;
}
.my-container .toast-mask {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  width: 100%;
  height: 100%;
}
.my-container .toast {
  width: 4.7rem;
  height: 5.4rem;
  background: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 100;
  margin: -2.7rem 0 0 -2.35rem;
  border-radius: 0.2rem;
}
.my-container .toast .toast-container {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
}
.my-container .toast .toast-container .success {
  width: 100%;
  height: 0.5rem;
  font-size: 0.36rem;
  color: #333333;
  line-height: 0.5rem;
  margin-top: 0.52rem;
}
.my-container .toast .toast-container .success-point {
  width: 100%;
  height: 0.34rem;
  font-size: 0.24rem;
  font-weight: 500;
  color: #333333;
  line-height: 17px;
  margin-top: 0.01rem;
}
.my-container .toast .toast-container .img {
  width: 3.24rem;
  height: 2.12rem;
  margin: 0.18rem 0 0.42rem 0;
}
.my-container .toast .toast-container .point-amount {
  color: #005B8A;
}
.my-container .toast .toast-container .toast-bottom {
  font-size: 0.28rem;
  color: #FF6600;
  line-height: 0.4rem;
  text-align: center;
  width: 100%;
}
.my-container .toast .toast-container .toast-bottom .toast-btn {
  width: 2.2rem;
  height: 0.68rem;
  line-height: 0.68rem;
  text-align: center;
  font-size: 0.28rem;
  color: #fff;
  background: #fe552e;
  border-radius: 0.34rem;
  margin-top: 0.16rem;
}
.my-container .reissue-success {
  letter-spacing: 0.02rem;
}
.my-container .reissue-success .toast-mask {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  width: 100%;
  height: 100%;
}
.my-container .reissue-success .toast {
  width: 4.68rem;
  height: 2.32rem;
  background: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 100;
  margin: -1.62rem 0 0 -2.34rem;
  border-radius: 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.my-container .reissue-success .toast .toast-containe {
  text-align: center;
}
.my-container .reissue-success .toast .toast-containe .success-txt {
  font-size: 0.36rem;
  font-weight: bold;
  color: #005B8A;
  margin-bottom: 0.2rem;
}
.my-container .reissue-success .toast .toast-containe .success-poin {
  font-size: 0.24rem;
  font-weight: 500;
  color: #333333;
}
.my-container .my-sty {
  font-size: 0.34rem;
  line-height: 0.46rem;
}
.my-container .presented {
  border-left: 1px solid #f6f6f6;
  height: 1rem;
}
.my-container .head-box {
  position: relative;
  height: 2.4rem;
  background: #fff;
  padding: 0.42rem 0.24rem 0.42rem 0.42rem;
  font-size: 0.38rem;
}
.my-container .head-box .head-img {
  width: 1.56rem;
  height: 1.56rem;
  border-radius: 50%;
  margin-right: 0.3rem;
}
.my-container .head-box .info-box {
  padding: 0.22rem 0;
  font-weight: 500;
  white-space: nowrap;
}
.my-container .head-box .info-box .integral-box {
  font-size: 0.24rem;
  color: #333;
  background: url("../../assets/icon_detail@3x.png") no-repeat right bottom;
  background-size: 0.32rem 0.32rem;
  padding-right: 0.32rem;
  white-space: nowrap;
}
.my-container .head-box .info-box .integral-number {
  font-size: 0.44rem;
  color: #333;
  margin-right: 0.06rem;
  font-weight: bold;
}
.my-container .head-box .detail_box {
  width: 20%;
  height: 100%;
}
.my-container .head-box .detail_box .detail_icon {
  width: 0.32rem;
  height: 0.32rem;
  background-image: url('../../assets/icon_detail@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
  .my-container .head-box .detail_box .detail_icon {
    background-image: url('../../assets/icon_detail@3x.png');
  }
}
.my-container .order-box {
  height: 2.44rem;
  background: #fff;
  margin-bottom: 0.2rem;
  margin-top: 0.2rem;
}
.my-container .order-box .order-title {
  height: 0.68rem;
  padding-right: 0.46rem;
  padding-left: 0.24rem;
  background: white url(../../assets/icon_more@3x.png) no-repeat 97% 50%;
  background-size: 0.14rem 0.24rem;
}
.my-container .order-box .order-title .left {
  font-size: 0.32rem;
  color: #2b2b2b;
}
.my-container .order-box .order-title .right {
  font-size: 0.24rem;
  color: #999999;
}
.my-container .order-box .order-type {
  height: 1.76rem;
  padding: 0.44rem 0.3rem 0.24rem 0.38rem;
  font-size: 0.28rem;
  color: #999999;
}
.my-container .order-box .order-type .order-item {
  height: 100%;
  position: relative;
}
.my-container .order-box .order-type .order-item i {
  font-size: 0.48rem;
  color: var(--main-color);
}
.my-container .order-box .order-type .order-item .mark {
  position: absolute;
  background: #ec5b55;
  border-radius: 50%;
  width: 0.28rem;
  height: 0.28rem;
  font-size: 0.2rem;
  color: #fff;
  line-height: 0.28rem;
  text-align: center;
  right: 0;
  top: -0.28rem;
}
.my-container .tab-list {
  background: #fff;
  margin-bottom: 0.2rem;
}
.my-container .tab-list .tab-item {
  width: 100%;
  height: 0.88rem;
  font-size: 0.32rem;
  color: #333333;
  background: white url(../../assets/icon_detail@3x.png) no-repeat 97% 50%;
  background-size: 0.4rem 0.4rem;
}
.my-container .tab-list .tab-item img {
  width: 0.48rem;
  height: 0.48rem;
  margin: 0 0.2rem 0 0.3rem;
}
.my-container .tab-list .tab-item .right-tab {
  width: 100%;
  height: 100%;
  line-height: 0.88rem;
}
.my-container .tab-list .tab-item .right-tab span {
  color: #999;
  margin-left: 0.32rem;
}
.my-container .tab-list .tab-item .feedbackStyle {
  margin-left: 0.2rem;
}
.PopUp {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999999;
  bottom: 0%;
}
.PopUp .bot-mobile {
  width: 80%;
  position: absolute;
  height: 3rem;
  bottom: 4rem;
  left: 0.5rem;
  text-align: center;
  line-height: 80px;
  background-color: #fff;
  margin-left: 0.3rem;
  border-radius: 10px;
}
.PopUp .bot-mobile img {
  width: 0.5rem;
  height: 0.5rem;
  float: right;
  margin: 0.2rem;
}
.PopUp .bot-mobile .service-phone {
  font-size: 19px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  position: absolute;
  margin-top: 0.5rem;
}
.PopUp .bot-mobile .service-phone span {
  color: #000;
}
.PopUp .bot-mobile .servicepnone {
  display: inline-block;
  width: 2rem;
  height: 0.5rem;
  font-size: 16px;
  color: #000;
  margin-top: 0.25rem;
}
.PopUp .bot-mobile .dial {
  left: 0rem;
  width: 1.5rem;
}
.PopUp .bot-mobile .dial,
.PopUp .bot-mobile .cancel {
  position: absolute !important;
  font-size: 15px;
  border-radius: 0 0 0 10px;
  line-height: 0.8rem !important;
  height: 0.8rem;
  width: 3rem;
  line-height: 0.5rem;
  text-align: center;
  color: #fff;
  top: 2.2rem !important;
  background: var(--main-color);
}
.PopUp .bot-mobile .cancel {
  left: 50% !important;
  width: 50%;
  border-radius: 0 0  10px 0;
  background: #f1f1f1;
  color: var(--main-color);
}
.newly {
  background-color: #fff;
  height: 1.35rem;
  margin-bottom: 0.2rem;
}
.newly .newlyDv {
  display: flex;
  border-top: 1px solid #f6f6f6;
  margin: 0rem 0.4rem 0rem 0.4rem;
}
.newly .newlyDv .balanceImg {
  height: 50%;
  background: url("../../assets/rechsrge.png") no-repeat center;
  background-size: 24px;
  margin: 0.1rem 0;
}
.newly .newlyDv .jifen {
  height: 50%;
  background: url("../../assets/jfen.png") no-repeat center;
  background-size: 24px;
  margin: 0.1rem 0;
}
.newly .newlyDv .jifenzs {
  height: 50%;
  background: url("../../assets/jjfenzs.png") no-repeat center;
  background-size: 24px;
  margin: 0.1rem 0;
}
.newly .newlyDv .balance {
  display: inline-block;
  flex: 1;
  border-right: 1px solid #f6f6f6;
  height: 1rem;
  text-align: center;
  margin-top: 0.2rem;
}
.newly .newlyDv .balance .balanceFigure {
  height: 50%;
  font-size: 0.24rem;
  color: #333333;
}
.newly .newlyDv .balanceChaoWei {
  display: inline-block;
  flex: 1;
  height: 1rem;
  text-align: center;
  margin-top: 0.2rem;
}
.newly .newlyDv .balanceChaoWei .balanceFigure {
  height: 50%;
  font-size: 0.24rem;
  color: #333333;
}
.newly .newlyDv .integral {
  display: inline-block;
  flex: 1;
  height: 1rem;
  text-align: center;
  margin-top: 0.2rem;
  color: #333333;
  font-size: 0.24rem;
}
.newly .newlyDv .integral .integralImg {
  height: 50%;
  margin: 0.1rem 0;
  background: url("../../assets/conversion.png") no-repeat center;
  background-size: 24px;
}
.newly .newlyDv .integral .integralFigure {
  height: 50%;
}
.newly .newlyDv .kunshang {
  line-height: 1rem;
  font-size: 0.35rem;
}
