.border-none[data-v-1fb7462f]:before {
  display: none;
}
.border-none[data-v-1fb7462f]:after {
  display: none;
}
.flex-fix[data-v-1fb7462f] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-1fb7462f]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-1fb7462f] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-1fb7462f] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-1fb7462f] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-1fb7462f] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-1fb7462f] {
  *zoom: 1;
}
.clear-fix[data-v-1fb7462f]:before,
.clear-fix[data-v-1fb7462f]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.dialog .toast-mask[data-v-1fb7462f] {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  width: 100%;
  height: 100%;
}
.dialog .toast[data-v-1fb7462f] {
  width: 4.68rem;
  height: 3.08rem;
  background: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 100;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius: 0.2rem;
}
.dialog .toast .toast-container[data-v-1fb7462f] {
  width: 4.68rem;
  height: 2.04rem;
}
.dialog .toast .toast-container .dialog-top[data-v-1fb7462f] {
  width: 4.68rem;
  height: 2.04rem;
  border-bottom: 0.02rem solid #F5F5F5;
  letter-spacing: 0.02rem;
}
.dialog .toast .toast-container .dialog-top .success[data-v-1fb7462f] {
  width: 100%;
  height: 0.4rem;
  font-size: 0.28rem;
  font-weight: 500;
  color: #333333;
  line-height: 0.4rem;
  margin: 0.34rem 0 0 0.4rem;
}
.dialog .toast .toast-container .dialog-top .myPage[data-v-1fb7462f] {
  text-align: center;
  color: #333333;
  font-size: 0.28rem;
  font-weight: 500;
  padding: 0.2rem 0 0.7rem 0;
}
.dialog .toast .toast-container .dialog-top .success-point[data-v-1fb7462f] {
  color: #005B8A;
  font-size: 0.4rem;
  text-align: center;
  padding: 0.5rem 0 0.3rem 0;
}
.dialog .toast .toast-container .dialog-btn[data-v-1fb7462f] {
  height: 1.04rem;
  position: relative;
  letter-spacing: 0.02rem;
}
.dialog .toast .toast-container .dialog-btn .btn[data-v-1fb7462f] {
  width: 2.335rem;
  height: 1.04rem;
  float: left;
  text-align: center;
  line-height: 1.04rem;
}
.dialog .toast .toast-container .dialog-btn .close-btn[data-v-1fb7462f] {
  font-size: 0.24rem;
  color: #acacac;
}
.dialog .toast .toast-container .dialog-btn .confirm-btn[data-v-1fb7462f] {
  color: #005B8A;
  font-size: 0.28rem;
}
.dialog .toast .toast-container .dialog-btn .road[data-v-1fb7462f] {
  float: left;
  width: 0.01rem;
  height: 0.42rem;
  border: 0.01rem solid #F5F5F5;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -0.005rem;
  margin-top: -0.21rem;
}
