@import "../../assets/iconfont/iconfont.css";
.border-none[data-v-7fc0ad74]:before {
  display: none;
}
.border-none[data-v-7fc0ad74]:after {
  display: none;
}
.flex-fix[data-v-7fc0ad74] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-7fc0ad74]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-7fc0ad74] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-7fc0ad74] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-7fc0ad74] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-7fc0ad74] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-7fc0ad74] {
  *zoom: 1;
}
.clear-fix[data-v-7fc0ad74]:before,
.clear-fix[data-v-7fc0ad74]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.nav-container[data-v-7fc0ad74] {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 0.98rem;
  background: #fff;
  -webkit-box-shadow: 0 -1px 0.2rem 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 -1px 0.2rem 0 rgba(0, 0, 0, 0.1);
  z-index: 99999;
}
.nav-container .nav-item[data-v-7fc0ad74] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
}
.nav-container .nav-item .icon[data-v-7fc0ad74] {
  width: 0.48rem;
  height: 0.48rem;
  font-size: 0.48rem;
  color: #999;
  margin-bottom: 0.08rem;
}
.nav-container .nav-item .nav-txt[data-v-7fc0ad74] {
  font-size: 0.22rem;
  color: #999;
  line-height: 0.32rem;
}
.nav-container .nav-item .yi_ic_home_blue_[data-v-7fc0ad74],
.nav-container .nav-item .yi_ic_type_blue_[data-v-7fc0ad74],
.nav-container .nav-item .yi_ic_shopping_blue_[data-v-7fc0ad74],
.nav-container .nav-item .yi_ic_my_blue_[data-v-7fc0ad74] {
  color: var(--main-color);
}
.nav-container .nav-item.router-link-active .nav-txt[data-v-7fc0ad74] {
  color: var(--main-color);
}
.nav-container .nav-item .grey[data-v-7fc0ad74] {
  color: #b3b3b3;
}
